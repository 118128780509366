<template>
	<div class="mail">
		<form class="form">
			<div class="form-group">
				<label>From</label>

				<p class="mail-text">{{ mailData.fromSender }}</p>
			</div>

			<div class="form-group">
				<label>To</label>

				<p class="mail-text">{{ mailData.toRecipients }}</p>
			</div>

			<div class="form-group">
				<label>Cc</label>

				<p class="mail-text">{{ mailData.ccRecipients }}</p>
			</div>

			<div class="form-group">
				<label>Bcc</label>

				<p class="mail-text">{{ mailData.bccRecipients }}</p>
			</div>

			<div class="form-group">
				<label>Sent</label>

				<p class="mail-text">{{ mailData.formattedDateTime }}</p>
			</div>

			<div class="form-group">
				<label>Subject</label>

				<p class="mail-text">{{ mailData.subject }}</p>
			</div>
		</form>

		<div class="mail-message">
			<p><span v-html="mailData.message"></span></p>
		</div>
	</div>
</template>

<script>
export default {
	name: "MailMessage",
	data() {
		return {
			mailData: {
				fromSender: "",
				toRecipients: "",
				ccRecipients: "",
				bccRecipients: "",
				sentDateTime: "",
				formattedDateTime: "",
				subject: "",
				message: "",
			},
		};
	},
	async created() {
		let request = {
			mailLogId: this.$route.params.mail.mailLogId,
			mailFileName: this.$route.params.mail.mailFileName,
		};

		try {
			const res = await this.$http.post(`/api/mail/detail`, request);
			this.mailData = res.data;
		} catch (error) {
			console.log(error);
		}
	},
};
</script>

<style lang="scss" scoped>
.mail {
	position: relative;

	&-text {
		font-size: 1.4rem;
		margin: 0;
	}

	&-message {
		display: block;
		margin-top: 0.2rem;
		padding: 0.8rem;
		border: 1px solid $color-black;
		overflow: scroll;
		width: 740px;
		height: 600px;
		font-size: 1.4rem;

		&::-webkit-scrollbar {
			width: initial;
		}
	}
}
</style>
